import React, { useContext } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/hooks'
import { Menu as UikitMenu, MenuEntry } from '@pancakeswap-libs/uikit'
// import contracts from '../../config/constants/contracts'
import BigNumber from 'bignumber.js'
// import config from './config'
// import { getRefUrl } from '../../utils'
import contracts from '../../config/constants/contracts'

// const refer = getRefUrl()
// hack to force the set of the cockie.
// const refParam = refer === '0x0000000000000000000000000000000000000000' ? '' : `?ref=${refer}`
// console.log('ref', refParam)

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const token = contracts.cake[process.env.REACT_APP_CHAIN_ID]
  const mc = contracts.masterChef[process.env.REACT_APP_CHAIN_ID]

  const originalPriceUsd = usePriceCakeBusd()
  let cakePriceUsd = originalPriceUsd
  if (process.env.REACT_APP_PRICE_DEFAULT) {
    cakePriceUsd = new BigNumber(process.env.REACT_APP_PRICE_DEFAULT)
  }

  const config = () => {
    const cfg: MenuEntry[] = [
      {
        label: 'Home',
        icon: 'HomeIcon',
        href: '/',
      },
      {
        label: 'Destruction Kingdom',
        icon: 'IfoIcon',
        href: '/destruction-kingdom',
        // tag: {
        //   title: 'live',
        //   variant: 'secondary',
        // },
      },
      {
        label: 'Farms',
        icon: 'FarmIcon',
        href: '/farms',
        tag: {
          title: 'ended',
          variant: 'secondary',
        },
      },
      {
        label: 'Pools',
        icon: 'PoolIcon',
        href: '/pools',
        tag: {
          title: 'ended',
          variant: 'secondary',
        },
      },
      {
        label: 'Vaults',
        icon: 'VaultsIcon',
        href: '/vaults',
        tag: {
          title: 'by Sandman',
          variant: 'secondary',
        },
      },
      {
        label: 'Vaults',
        icon: 'VaultsIcon',
        href: '/vaults-polycrystal',
        tag: {
          title: 'by PolyCrystal',
          variant: 'secondary',
        },
      },
      {
        label: 'NFT Sale',
        icon: 'NftIcon',
        href: '/nft-sale',
        tag: {
          title: 'ended',
          variant: 'secondary',
        },
      },
      {
        label: 'NFT Cards',
        icon: 'NftIcon',
        href: '/nft-cards',
      },
      {
        label: 'NFT Ranking',
        icon: 'NftIcon',
        href: '/nft-ranking',
      },
      // {
      //   label: 'NFT Airdrop',
      //   icon: 'NftIcon',
      //   href: '/nft-airdrop',
      //   // tag: {
      //   //   title: 'live',
      //   //   variant: 'secondary',
      //   // },
      // },
      {
        label: 'The Endless',
        icon: 'LayerIcon',
        href: '/the-endless',
      },
      {
        label: 'ANTI-BOT',
        icon: 'AuditIcon',
        href: '/anti-bot',
      },
      {
        label: 'Tokens',
        icon: 'MetamaskIcon',
        href: 'https://metamask.sandman.finance',
      },
      // {
      //   label: 'Referral',
      //   icon: 'ReferralIcon',
      //   href: '/referral',
      // },
      // {
      //   label: 'Lottery (coming soon)',
      //   icon: 'TicketIcon',
      //   href: '/lottery',
      // },
      // {
      //   label: 'Prediction (coming soon)',
      //   icon: 'PredictionIcon',
      //   href: '/lottery',
      // },
      // {
      //   label: 'NFT',
      //   icon: 'NftIcon',
      //   href: '/nft',
      // },
      // {
      //   label: 'Features',
      //   icon: 'FeaturesIcon',
      //   items: [
      //     {
      //       label: 'Automatic LP',
      //       href: 'https://docs.sandman.farm/tokenomics/automatic-liquidity',
      //     },
      //     {
      //       label: 'Automatic Burning',
      //       href: 'https://docs.sandman.farm/tokenomics/automatic-burning',
      //     },
      //     {
      //       label: 'Deposit Shield',
      //       href: 'https://docs.sandman.farm/deposit-shield',
      //     },
      //     {
      //       label: 'Anti-Whale',
      //       href: 'https://docs.sandman.farm/tokenomics/anti-whale',
      //     },
      //   ],
      // },
      {
        label: 'Charts',
        icon: 'InfoIcon',
        items: [
          {
            label: 'PooCoin',
            href: `https://polygon.poocoin.app/tokens/${token}`,
          },
          {
            label: 'Arken',
            href: `https://swap.arken.finance/tokens/polygon/${token}`,
          },
        ],
      },
      {
        label: 'Listings',
        icon: 'ListingIcon',
        items: [
      //     {
      //       label: 'Vfat',
      //       href: 'https://vfat.tools/polygon/destructionfarm/',
      //     },
          {
            label: 'DappRadar',
            href: 'https://dappradar.com/polygon/defi/destruction',
          },
          {
            label: 'FarmScan',
            href: `https://polygon.farmscan.io/address/${mc}`,
          },
        ],
      },
      {
        label: 'Tutorials',
        icon: 'DocsIcon',
        href: '/tutorials',
      },
      {
        label: 'Wallpapers',
        icon: 'DocsIcon',
        href: '/wallpapers',
      },
      {
        label: 'Team',
        icon: 'GroupsIcon',
        href: '/team',
      },
    ]

    if (originalPriceUsd.isNaN()) {
      cfg.push(
        {
          label: 'Trade (coming soon)',
          icon: 'TradeIcon',
          href: '#',
        }
      )
    } else {
      cfg.splice(1, 0,
        {
          label: 'Trade',
          icon: 'TradeIcon',
          items: [
            {
              label: 'Exchange',
              href: `https://quickswap.exchange/#/swap?outputCurrency=${token}`,
            },
            {
              label: 'Liquidity',
              href: `https://quickswap.exchange/#/add/${token}/ETH`,
            },
          ],
        }
      )
    }

    return cfg
  }

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config()}
      priceLink={`https://swap.arken.finance/tokens/polygon/${token}`}
      rugDocLink="https://rugdoc.io/project/destruction/"
      rugDocText="KYC / LOW RISK"
      auditSCLink="https://docs.destruction.sandman.finance/security/audit-sc-kyc"
      auditText="ANTI-BOT"
      auditLink="/anti-bot"
      {...props}
    />
  )
}

export default Menu
