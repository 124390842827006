const roundToTwoDp = (number) => Math.round(number * 100) / 100

export const calculateVaultEarnedPerThousandDollars = ({ numberOfDays, farmApy, cakePrice, compoundFrequency, performanceFee }) => {
  const principal = 1000 / cakePrice

  // This is a translation of the typical mathematical compounding APY formula. Details here: https://www.calculatorsoup.com/calculators/financial/compound-interest-calculator.php
  // const finalAmount = principal * (1 + apyAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear)
  const finalAmount = principal * (1 + getApy(farmApy, compoundFrequency, numberOfDays, performanceFee))

  // To get the cake earned, deduct the amount after compounding (finalAmount) from the starting CAKE balance (principal)
  const interestEarned = finalAmount - principal
  return roundToTwoDp(interestEarned)
}

export const calculateCakeEarnedPerThousandDollars = ({ numberOfDays, farmApy, cakePrice }) => {
  // Everything here is worked out relative to a year, with the asset compounding daily
  // const timesCompounded = 365
  //   We use decimal values rather than % in the math for both APY and the number of days being calculates as a proportion of the year
  // const apyAsDecimal = farmApy / 100
  // const daysAsDecimalOfYear = numberOfDays / timesCompounded
  //   Calculate the starting CAKE balance with a dollar balance of $1000.
  const principal = 1000 / cakePrice

  // This is a translation of the typical mathematical compounding APY formula. Details here: https://www.calculatorsoup.com/calculators/financial/compound-interest-calculator.php
  // const finalAmount = principal * (1 + apyAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear)
  const finalAmount = principal * (1 + getApy(farmApy, 1, numberOfDays, 0))

  // To get the cake earned, deduct the amount after compounding (finalAmount) from the starting CAKE balance (principal)
  const interestEarned = finalAmount - principal
  return roundToTwoDp(interestEarned)
}

export const apyModalRoi = ({ amountEarned, amountInvested }) => {
  const percentage = (amountEarned / amountInvested) * 100
  return percentage.toFixed(2)
}

// APY for Auto Compound
export const getApy = (apr: number, compoundFrequency = 1, days = 365, performanceFee = 0) => {
  const daysAsDecimalOfYear = days / 365
  const aprAsDecimal = apr / 100
  const timesCompounded = 365 * compoundFrequency
  let apyAsDecimal = (apr / 100) * daysAsDecimalOfYear
  if (timesCompounded > 0) {
    apyAsDecimal = (1 + aprAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear) - 1
  }
  if (performanceFee) {
    const performanceFeeAsDecimal = performanceFee / 100
    const takenAsPerformanceFee = apyAsDecimal * performanceFeeAsDecimal
    apyAsDecimal -= takenAsPerformanceFee
  }

  return apyAsDecimal
}

export const getRoi = ({ amountEarned, amountInvested }: { amountEarned: number; amountInvested: number }) => {
  if (amountInvested === 0) {
    return 0
  }
  const percentage = (amountEarned / amountInvested) * 100
  return percentage
}
