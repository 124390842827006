import contracts from './contracts'
import { VaultConfig, QuoteToken } from './types'

const vaults: VaultConfig[] = [
  // {
  //   pid: 0,
  //   farmPid: 2,
  //   isTokenOnly: true,
  //   lpSymbol: 'DESTRUCTION',
  //   decimal: 18,
  //   lpAddresses: {
  //     137: '0x1c50b2187B2fB666A7aA9CFbD032C5cb0B7fd189',
  //   },
  //   tokenSymbol: 'DESTRUCTION',
  //   tokenAddresses: {
  //     137: '0xCa4992F01B63C7cEB98505946b79D7D8855449F9',
  //   },
  //   quoteTokenSymbol: QuoteToken.USDC,
  //   quoteTokenAdresses: contracts.usdc,
  //   earnSymbol: 'USDC',
  //   earnDecimal: 6,
  //   from: 'SANDMAN',
  //   abiFile: 'vaultDestructionStrategy',
  //   strategyAddresses: {
  //     137: '0x394dC858E8637DA42E38D60AC1279CaF23460E08',
  //   },
  //   compoundFrequency: 288,
  //   performanceFee: 0,
  // },
  {
    pid: 1,
    farmPid: 1,
    lpSymbol: 'DESTRUCTION-MATIC',
    decimal: 18,
    lpAddresses: {
      137: '0x1c50b2187B2fB666A7aA9CFbD032C5cb0B7fd189',
    },
    tokenSymbol: 'DESTRUCTION',
    tokenAddresses: {
      137: '0xCa4992F01B63C7cEB98505946b79D7D8855449F9',
    },
    quoteTokenSymbol: QuoteToken.MATIC,
    quoteTokenAdresses: contracts.wmatic,
    earnSymbol: 'DESTRUCTION-MATIC',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x79E53907C4339897A0c8a9EA31a64741e3045ce0',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 0,
    farmPid: 0,
    lpSymbol: 'DESTRUCTION-USDC',
    decimal: 18,
    lpAddresses: {
      137: '0x287bD6518aE54a4299BD766faa6DF3ED795fB6C1',
    },
    tokenSymbol: 'DESTRUCTION',
    tokenAddresses: {
      137: '0xCa4992F01B63C7cEB98505946b79D7D8855449F9',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESTRUCTION-USDC',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x680665CE3b46C0ED6D8B7E59bC36cCF54b350442',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 4,
    farmPid: 2,
    isTokenOnly: true,
    lpSymbol: 'DESTRUCTION',
    decimal: 18,
    lpAddresses: {
      137: '0x287bD6518aE54a4299BD766faa6DF3ED795fB6C1',
    },
    tokenSymbol: 'DESTRUCTION',
    tokenAddresses: {
      137: '0xCa4992F01B63C7cEB98505946b79D7D8855449F9',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESTRUCTION',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x7dd81358ebCb1B22c21623130e57Adc1E0475a11',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 3,
    farmPid: 13,
    isTokenOnly: true,
    lpSymbol: 'DESPAIR',
    decimal: 18,
    lpAddresses: {
      137: '0x92BB40fa63EB4f402Cae5bDDAb3369a4Fb3e33bF',
    },
    tokenSymbol: 'DESPAIR',
    tokenAddresses: {
      137: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DESPAIR',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0xF137DDF017c0BB00a610b649379550C2a63a67a7',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
  {
    pid: 2,
    farmPid: 12,
    isTokenOnly: true,
    lpSymbol: 'DELIRIUM',
    decimal: 18,
    lpAddresses: {
      137: '0x2F051dB1634eEa2081590860ab8E756D452f2239',
    },
    tokenSymbol: 'DELIRIUM',
    tokenAddresses: {
      137: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    earnSymbol: 'DELIRIUM',
    earnDecimal: 18,
    from: 'SANDMAN',
    abiFile: 'vaultStrategyMasterchef',
    strategyAddresses: {
      137: '0x017dd14DbAdf69aE10a9218021773bDc505efe20',
    },
    compoundFrequency: 288,
    performanceFee: 6.66,
  },
]

export default vaults
