import { PoolConfig, PoolCategory } from './types'
// import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 3,
    tokenName: 'SALSA',
    stakingTokenName: 'DESTRUCTION',
    stakingTokenAddress: '0xCa4992F01B63C7cEB98505946b79D7D8855449F9',
    stakingTokenLpAddress: '0x1c50b2187B2fB666A7aA9CFbD032C5cb0B7fd189',
    stakingTokenQuoteTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    contractAddress: {
      137: '0x1E67B6FB4220C5626D489d4103593707e91Df978',
    },
    lpAddress: {
      137: '0x1844be27768751C3888056234E08B49530633454',
    },
    tokenAddress: {
      137: '0x64367C7A9e91da86386964DCFfECf0EC48D2fbc0',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.PARTNERSHIP,
    projectLink: 'https://salsa.tacoparty.finance/',
    harvest: true,
    tokenPerBlock: '0.006944444444',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 3000,
    withdrawLockup: 96,
    harvestLockup: 4,
    partnerColor: '182, 25, 36',
    experienceBalance: 25000,
  },
  {
    sousId: 2,
    tokenName: 'MAI',
    stakingTokenName: 'DESTRUCTION',
    stakingTokenAddress: '0xCa4992F01B63C7cEB98505946b79D7D8855449F9',
    stakingTokenLpAddress: '0x1c50b2187B2fB666A7aA9CFbD032C5cb0B7fd189',
    stakingTokenQuoteTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    contractAddress: {
      137: '0x5ddB968f78f04D7DD637F54f60107485D80C41E1',
    },
    lpAddress: {
      137: '0x7805B64e2d99412d3b8F10Dfe8fc55217C5cc954',
    },
    tokenAddress: {
      137: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.mai.finance/',
    harvest: true,
    tokenPerBlock: '0.02469135802',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 2000,
    withdrawLockup: 54,
    harvestLockup: 4,
    partnerColor: '254, 134, 140',
    experienceBalance: 10000,
  },
  {
    sousId: 1,
    tokenName: 'DAI',
    stakingTokenName: 'DESPAIR',
    stakingTokenAddress: '0x898F53e0365b3e8114227Df4E811766afCA960b8',
    stakingTokenLpAddress: '0xA8F74cebDeB6525BbF447B5e76443e4C46F3F7C3',
    stakingTokenQuoteTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    contractAddress: {
      137: '0xcf1C0f23CD59632A723B50848B4BefbBf89E89E8',
    },
    lpAddress: {
      137: '0xEEf611894CeaE652979C9D0DaE1dEb597790C6eE',
    },
    tokenAddress: {
      137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    },
    quoteTokenAdress: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://makerdao.com/',
    harvest: true,
    tokenPerBlock: '0.01736111111',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    stakingLimit: 0,
    poolBalance: 2500,
    withdrawLockup: 96,
    harvestLockup: 4,
    partnerColor: '253, 193, 52',
    experienceBalance: 15000,
  },
]

export default pools
